import React from 'react'
import '../../pages/style.css';
import ProjectCard from "./ProjectCard";
import { Container, Row, Col } from 'react-bootstrap'
import iPlay from '../../Assets/iPlay.jpg'
import mkto from '../../Assets/mkto.jpg'
import icow from '../../Assets/i-cow.jpg'
import volII from '../../Assets/volII.jpg'
import volI from '../../Assets/volI.jpg'
import iPhone from '../../Assets/iPhone.jpg'

function Projectlist() {
  return (
    <div className="projectbackground">
      <Container fluid className="project-section">
        <Container>
          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={iPlay}
                isBlog={false}
                title="iPlay Music App"
                description="iPlay music app is made with user-friendly interface for relaxing, studying and meditation. You can listen online/offline and also be able import your songs in your own choice."
                ghLink="https://www.behance.net/gallery/167495079/iPlay-Music-App-UI-and-Logo-Design"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={mkto}
                isBlog={false}
                title="Studio MKTO Portfolio Website"
                description="mkto is formed in 2023 by designers and computer nerds from a diverse background, mkto is a multifaceted creative agency. WE translate concepts..."
                ghLink="https://studiomkto001.web.app/"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={icow}
                isBlog={false}
                title="I-Cow Music App"
                description="I-Cow is a platform where you can import your favorite songs using the link and also can listen to the songs in your local device with multiple features, convenient and user-friendly."
                ghLink="https://www.behance.net/gallery/169460787/I-Cow-Music-App"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={volII}
                isBlog={false}
                title="Socail Media Design VOLII"
                description="This collection is focusing on social media promoting. I have designed various field such as music, bank, restaurant, hiring, and other posters."
                ghLink="https://www.behance.net/gallery/194433493/Social-media-poster-design-VOL2"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={volI}
                isBlog={false}
                title="Social Media Design VOLI"
                description="This collection I designed for Orkun App projects. It includes POSM such as stickers, banners, leaflets, cutboard, flyers, uniforms, and keychains..."
                ghLink="https://www.behance.net/gallery/194422421/Social-media-poster-design-VOL1"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={iPhone}
                isBlog={false}
                title="iPhone Website Clone"
                description="It is my first project to expanse my skill to UI/UX designer by learning how to clone website. The purpose is to learn the tools and prototyping."
                ghLink="https://www.behance.net/gallery/159552801/Apple-Website-Home-Page-Cloned"

              />
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  )
}
export default Projectlist