import React from "react";
import "../../pages/style.css";
import { Container, Row, Col } from "react-bootstrap";

function Aboutpage() {
  return (
    <div className="aboutpagebackground">
      <Container>
        <Row className="textbackground">
          <Col md={7}>
            <h3 className="aboutmetext">
              About <span>Me</span>
            </h3>
            <p className="aboutdetails">
            I am a self-taught UI/UX and Graphic designer. I have experience designing websites with a focus on providing the experience for all users. Moreover, I have extensive experience creating thousands of dynamic graphic artworks that effectively communicate and elevate brands across diverse business fields. I am currently living in PHNOM PENH, CAMBODIA, pursuing a degree in MANAGEMENT INFORMATION SYSTEMS.
            </p>
            <ul className="skilllist">
              <Row>
                <h3>Skills</h3>
                <Col md={7}>
                  <li>Photoshop</li>
                  <li>Illustrator</li>
                  <li>InDesign</li>
                  <li>Premiere Pro</li>
                  <li>After Effect</li>
                </Col>
                <Col md={5}>
                  <li>XD/Figma</li>
                  <li>HTML/CSS/Javascript</li>
                  <li>Digital Marketing</li>
                  <li>Photo Manipulation</li>
                  <li>Branding Design/Brand Identity</li>
                </Col>
              </Row>
            </ul>
          </Col>
          <Col md={5}>
            <div className="webimage"></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Aboutpage;
